function App() {
  return (
    <div>
      <embed
        width="100%"
        height="100%"
        src='./Clean_Code.pdf'
        type='application/pdf'
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
        }}
        scrolling="yes"
      />
    </div>
  );
}

export default App;
